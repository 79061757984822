<template>
<div class="dialog-mask">
    <div class="dialog">

    </div>
</div>
</template>

<script>
export default {
    name: 'dialog',
    props: {
        visible: {
            type: boolean,
            default: false
        },
        showHeader: {
            type: boolean,
            default: false
        }
    },
    data() {

    },
    methods: {

    }
}
</script>

<style>

</style>